import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDSnackbar from "components/MDSnackbar";
import styled from "styled-components";
import DatePicker from "react-multi-date-picker";
import { CircularProgress, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import GraficoMeta from "./alocação/tipo-ativo/graficos";
import { name } from "dayjs/locale/pt-br";
import AlocacaoPorAtivo from "./alocação/por-ativo/graficos";
import KeepMountedModal from "components/Modal";
import FormMetaAlocacao from "./alocação/meta/formMetaAlocacao";
import { getAllocationTarget, getCurrentAllocation } from "./alocação/api/allocationApi";
import { Link, Navigate } from "react-router-dom";
import { useMaterialUIController } from "context";

const PRO_TIER = 3;

function RebalancearCarteira() {
    const [controller, dispatch] = useMaterialUIController();
    const {
        userTier
    } = controller;
    const [successSB, setSuccessSB] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [allocationTarget, setAllocationTarget] = useState([]);
    const [currentAllocationTarget, setCurrentAllocationTarget] = useState([]);
    const [openSnackMessage, setOpenSnackMessage] = useState(null);

    useEffect(() => {
        getAssetsTypeAllocationTarget();
        getCurrentAssetsTypeAllocation();
    }, [])

    const getAssetsTypeAllocationTarget = async () => {
        setIsLoading(true);

        await getAllocationTarget()
            .then((data) => {
                setIsLoading(false);
                if (data?.errorCode) {
                    // openSnackBar({ type: 'error', message: `${data?.message}` });
                    return;
                }

                setAllocationTarget(data);

                // openSnackBar({ type: 'success', message: 'Operação realizada com sucesso' });
                // closeModal();
                // resetForm();
                // refreshList();
                // refreshSummaryBoxes();
            })
            .catch(e => {
                // openSnackBar({ type: 'error', message: `${e}` });
            });
    };

    const getCurrentAssetsTypeAllocation = async () => {
        setIsLoading(true);

        await getCurrentAllocation()
            .then((data) => {
                setIsLoading(false);
                if (data?.errorCode) {
                    // openSnackBar({ type: 'error', message: `${data?.message}` });
                    return;
                }

                setCurrentAllocationTarget(data);

                // openSnackBar({ type: 'success', message: 'Operação realizada com sucesso' });
                // closeModal();
                // resetForm();
                // refreshList();
                // refreshSummaryBoxes();
            })
            .catch(e => {
                // openSnackBar({ type: 'error', message: `${e}` });
            });
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    if (userTier != PRO_TIER){
       return <Navigate to="/pagamento" replace />
    }


        return (
            <DashboardLayout>
                <DashboardNavbar />
                <Grid item xs={12} sm={6} lg={3}>
                    <MDSnackbar
                        // color={openSnackMessage?.type}
                        icon="check"
                    // title={openSnackMessage?.message}
                    // content="Hello, world! This is a notification message"
                    // dateTime="11 mins ago"
                    // open={openSnackMessage}
                    // onClose={() => setOpenSnackMessage(false)}
                    // close={() => setOpenSnackMessage(false)}
                    // bgWhite
                    />
                </Grid>
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <StyledMDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="dark">
                                        Alocação por tipo do ativo
                                    </MDTypography>
                                </StyledMDBox>

                                <StyledDiv>
                                    <MDBox pt={4} pb={3} px={1} className="grafic">
                                        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                                            Alocação atual
                                        </MDTypography>
                                        <GraficoMeta data={currentAllocationTarget} />
                                    </MDBox>

                                    <MDBox pt={4} pb={3} px={1} className="grafic">
                                        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                                            Meta alocação
                                        </MDTypography>
                                        <GraficoMeta data={allocationTarget} />

                                        <MDButton variant="gradient" color="info" onClick={() => setIsOpen(!isOpen)}>Editar</MDButton>
                                        <KeepMountedModal
                                            isOpen={isOpen}
                                            closeModal={handleCloseModal}
                                            content={<FormMetaAlocacao
                                                title="Configuração - Meta de Alocação"
                                                refreshGraph={getAssetsTypeAllocationTarget}
                                                currentData={allocationTarget}
                                                openSnackBar={(e) => setOpenSnackMessage(e)}
                                                closeModal={handleCloseModal}
                                            />}
                                        />
                                    </MDBox>
                                </StyledDiv>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <StyledMDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="dark">
                                        Alocação por ativo
                                    </MDTypography>
                                </StyledMDBox>

                                <StyledDiv>
                                    <MDBox pt={4} pb={3} px={3} className="grafic">
                                        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                                            Meta alocação
                                        </MDTypography>
                                        <AlocacaoPorAtivo />
                                    </MDBox>
                                </StyledDiv>
                                <StyledDivButton>
                                    <Link to={'/rebalancear/pontuar'}>

                                        <MDButton
                                            variant="gradient"
                                            color="info"
                                        // onClick={() => setIsOpen(!isOpen)}
                                        >
                                            Pontuar ativos
                                        </MDButton>
                                    </Link>
                                </StyledDivButton>

                                {/* 
                            <div>
                            -gráfico de barras com posição atual, e meta, por ativo igual teho no google sheets <br />
                            -adicionar call to action para dar pontos para os ativos<br />
                            -calcular compras em caso de novos aportos, com tomada de decição por alguns atributos tipo preço medio, percentual da carteira e etc...
                        </div> */}

                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
                <Footer />

                <Grid item xs={12} sm={6} lg={3}>
                    <MDSnackbar
                        color={openSnackMessage?.type}
                        icon="check"
                        title={openSnackMessage?.message}
                        open={openSnackMessage}
                        onClose={() => setOpenSnackMessage(false)}
                        close={() => setOpenSnackMessage(false)}
                    // bgWhite
                    />
                </Grid>
            </DashboardLayout>
        );
}

const StyledMDBox = styled(MDBox)`
  background: radial-gradient(circle at 221% 201%, #f28371 0%, #f28371 65%, transparent 0%) ,#f0ba8d !important; 
`;

const StyledDiv = styled.div`
    display: flex;
    justify-content: space-around;

    .grafic{
        width: 100%;
        display:flex;
        flex-direction: column;
        align-items: center;
    }
`;

const StyledDivButton = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 25px;
`;

export default RebalancearCarteira;
