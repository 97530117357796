import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layouts/dashboard/components/Projects/data";

// Api
import MDAvatar from "components/MDAvatar";
import { Link } from "react-router-dom";
import styled from "styled-components";
import getAssets from "layouts/dashboard/api/dashboardApi";
import { Pagination } from "@mui/material";

function Projects() {
  const { columns, rows } = data();
  const [menu, setMenu] = useState(null);
  const [assets, setAssets] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [orderBy, setOrderBy] = useState(null);
  const [orderDirection, setOrderDirection] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  useEffect(() => {
    //TODO: aqui chama a API para buscar a posição atual do user;
    getAssetsx();
  }, [, currentPage, orderBy, orderDirection]);


  const getAssetsx = async () => {
    setIsLoading((isLoading) => !isLoading)
    await getAssets(10, currentPage, orderBy, orderDirection).then((data) => {
      setAssets(data.results);
      setPageCount(data.pageCount);
      setIsLoading((isLoading) => !isLoading)
    });
  };

  const columnsx = [
    { Header: "Empresa", accessor: "logo", width: "25%", align: "left", },
    { Header: "Quantidade", accessor: "quantity", width: "25%", align: "center"},
    { Header: "PM", accessor: "averagePrice", align: "center", blockSorting: false },
    { Header: "Valor de mercado", accessor: "marketPrice", align: "center", blockSorting: true },
    { Header: "Total", accessor: "total", align: "center", blockSorting: false },
    { Header: "Percentual na carteira", accessor: "walletPercentage", align: "center"}, //TODO: mudar isso pra subida ou descida do ativo
  ];

  const sortData = (val) => {
    if (val != orderBy) {
      setOrderBy(val);
      setOrderDirection('asc')
      return;
    }

    if (orderDirection == 'asc') {
      setOrderDirection('desc');
      return;
    }

    if (orderDirection == 'desc') {
      setOrderDirection(null);
      return;
    }

    if (orderDirection == null) {
      setOrderDirection('asc');
      return;
    }
  };


  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} bgColor={'dark'} name={name} size="sm" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const rowsx = assets.map(e => (
    {
      logo: <Company image={e.logo} name={e.ticker} />,
      quantity: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {e.quantity}
        </MDTypography>),
      averagePrice: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          R$ {e.averagePrice.toFixed(2)}
        </MDTypography>
      ),
      marketPrice: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          R$ {e.marketPrice ? e.marketPrice?.toFixed(2) : '-'}
        </MDTypography>
      ),
      total: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          R$ {e.total.toFixed(2)}
        </MDTypography>
      ),
      walletPercentage: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {e.walletPercentage.toFixed(2)}%
        </MDTypography>
      ),
    }
  ));

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <StyledLink to="/posicao">
        <MenuItem onClick={closeMenu}>
          Gráfico posição
        </MenuItem>
      </StyledLink>
      <StyledLink to="/operation/extract">
        <MenuItem onClick={closeMenu}>
          Extrato operações
        </MenuItem>
      </StyledLink>
      <StyledLink to="/rebalancear/pontuar">
        <MenuItem onClick={closeMenu}>Rebalanceamento de carteira</MenuItem>
      </StyledLink>
    </Menu>
  );

  return (
    <Card className="tabelaAtivos">
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDBox display="flex" alignItems="center" lineHeight={0} >
            <Icon
              sx={{
                fontWeight: "bold",
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              done
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>Ativos</strong>
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center" lineHeight={0}>

            {/* <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>30 done</strong> this month
            </MDTypography> */}
          </MDBox>
        </MDBox>
        <MDBox color="text" px={2}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </MDBox>
        {renderMenu}
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns: columnsx, rows: rowsx }}
          showTotalEntries={false}
          isSorted={true}
          noEndBorder
          entriesPerPage={false}
          expandable
          sortData={sortData}
        />
      </MDBox>
      {pageCount > 1 && (
        <StyledPagination
          sx={{ display: 'flex', justifyContent: 'center', paddingTop: 4 }}
          disabled={isLoading}
          count={pageCount}
          boundaryCount={1}
          onChange={(e, page) => setCurrentPage(page)}
          size="small"
        />
      )}
    </Card>
  );
}

const StyledLink = styled(Link)`
color: #7B809A;
`;

const StyledPagination = styled(Pagination)`
    .MuiPagination-ul button{
        background: radial-gradient(circle at 225% 577%, #f28371 0%, #f28371 84%, transparent 0%),#F0BA8E!important;
        opacity: 0.8; 

        &[disabled]{
            opacity: 0.4; 
        }

        &.Mui-selected {
            opacity: 0.9; 
            background: radial-gradient(circle at -136% 568%, #f28371 0%, #f28371 98%, transparent 0%),#F0BA8E!important
        }
    }

    .MuiPagination-ul li{
        padding: 0 2px;
    }

    margin-bottom: 30px
`;

export default Projects;
