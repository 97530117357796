// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import styled, { css } from "styled-components";

function SidenavCollapse({ icon, name, active, isPro, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor, userTier } = controller;

  const PRO_TIER = 3;

  return (
    <StyledDListItem className="menu-list-styled" component="li" $disabled={userTier != PRO_TIER && isPro}>
      <MDBox
        {...rest}
        sx={(theme) =>
          collapseItem(theme, {
            active,
            transparentSidenav,
            whiteSidenav,
            darkMode,
            sidenavColor,
          })
        }
        >
        <ListItemIcon
          sx={(theme) =>
            collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
          }
          >
          {typeof icon === "string" ? (
            <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
          ) : (
            icon
          )}
        </ListItemIcon>

        <ListItemText
          primary={name}
          sx={(theme) =>
            collapseText(theme, {
              miniSidenav,
              transparentSidenav,
              whiteSidenav,
              active,
            })
          }
          />
      </MDBox>
      {/* TODO: Verificar se o user não tem o plano pro */}
      {userTier != PRO_TIER && isPro && (
        <StyledDiv>Pro</StyledDiv>
      )}
    </StyledDListItem>
     
  );
}


const StyledDListItem = styled(ListItem)`
  div.MuiBox-root {
    ${({ $disabled }) =>
      $disabled &&
        css`
            // color: red;
            background: rgba(255, 255, 255, 0.05);
            
            span{
              color: rgba(255, 255, 255, 0.6);
            }
          `}

`;


const StyledDiv = styled.div`
    position: absolute;
    left: 199px;
    top: 30px;
    background-color: #364d9d;
    color: #fff;
    border-radius: 10px;
    padding: 0px 8px;
    font-size: 14px;
`;



// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
