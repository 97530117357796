import { Card, CircularProgress, FormControlLabel, Grid, Icon, Radio, RadioGroup, ThemeProvider, createTheme } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import postRevenueExpense, { deleteRevenueExpense, getRevenueExpenseById, updateRevenueExpense } from './revenueExpensesApi';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ptBR } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/pt-br';
import dayjs from "dayjs";
import { Steps } from "intro.js-react";
import { useMaterialUIController } from "context";

function AddNewRevenueExpenseForm({ title, id, openSnackBar, closeModal, refreshList, refreshSummaryBoxes }) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const [operationType, setOperationType] = useState('');
    const [date, setDate] = useState(dayjs());
    const [value, setValue] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoagding] = useState(false);
    const [stepsEnabled, setStepsEnabled] = useState(false);

    useEffect(() => {
        if (id) {
            getRevenueExpense();
        }

        const onboarding = localStorage.getItem("patrimonio360onboarding-modal-revenuexexpenses");
        setStepsEnabled(onboarding != 'true');
    }, []);

    const getRevenueExpense = async () => {
        await getRevenueExpenseById(id)
            .then(e => {
                setDate(e.makeDate);
                setValue(e.value);
                setName(e.name);
                setOperationType(e.type == 1 ? 'receita' : 'despesa');
            });
    }

    const theme = createTheme(
        {
            palette: {
                primary: { main: '#1976d2' },
            },
        },
        ptBR,
    );

    const handleDateChange = (date) => {
        console.log(dayjs());
        const currentTime = dayjs().format('HH:mm:ss');

        const fullDateTime = dayjs(date).format(`YYYY-MM-DD`) + 'T' + currentTime;
        setDate(fullDateTime);
    };

    const handleSave = async () => {
        setIsLoagding(true);

        const body = {
            type: operationType === 'receita' ? 1 : 2,
            date: date,
            description: '',
            value: value,
            name: name
        };

        if (id) {
            await updateRevenueExpense(id, body)
                .then((data) => {
                    setIsLoagding(false);
                    openSnackBar({ type: 'success', message: 'Operação realizada com sucesso' });
                    closeModal();
                    refreshList();
                    refreshSummaryBoxes();
                })
                .catch(e => {
                    openSnackBar({ type: 'alert', message: `${e.message}` });
                });
        } else {
            await postRevenueExpense(body)
                .then((data) => {
                    setIsLoagding(false);
                    if (data?.errorCode) {
                        openSnackBar({ type: 'error', message: `${data?.message}`, isUpgradeMessage: data?.errorCode == 402 });
                        return;
                    }

                    openSnackBar({ type: 'success', message: 'Operação realizada com sucesso' });
                    // closeModal();
                    resetForm();
                    refreshList();
                    // refreshSummaryBoxes();
                })
                .catch(e => {
                    openSnackBar({ type: 'error', message: `${e}` });
                });
        }
    };

    const handleDelete = async () => {
        setIsLoagding(true);
        await deleteRevenueExpense(id)
            .then((data) => {
                setIsLoagding(true);
                openSnackBar({ type: 'success', message: 'Operação realizada com sucesso' });
                closeModal();
                refreshList();
                refreshSummaryBoxes();
            });
    };

    const resetForm = () => {
        setName('');
        setValue('');
        setDate('');
        setOperationType('');
    };

    const stepsDesktop = [
        {
            element: '#input-ativo',
            intro: 'Aqui vai o nome da receita ou despesa',
        },
        {
            element: '.formNewRevenueXExmpenseValue',
            intro: 'Adicione o valor aqui!',
        },
        {
            element: '.formNewRevenueXExmpenseDate',
            intro: 'Insira a data da receita ou despesa',
        },
        {
            element: '.formNewRevenueXExmpenseRadio',
            intro: 'Seleciona se é receita ou despesa, e é só salvar!',
        },
    ];

    return (
        <StyledContainer>
            <Steps
                enabled={stepsEnabled}
                steps={stepsDesktop}
                initialStep={0}
                onExit={() => setStepsEnabled(false)}
                // exitOnEsc
                // onBeforeExit={false}
                exitOnOverlayClick={false}
                onComplete={() => localStorage.setItem("patrimonio360onboarding-modal-revenuexexpenses", true)}
                options={{
                    doneLabel: 'Entendido!',
                    prevLabel: '<',
                    nextLabel: '>'
                }}
            />
            {/* <Grid item xs={12} sm={6} lg={3}>
                <MDSnackbar
                    color={openSnackMessage?.type}
                    icon="check"
                    title={openSnackMessage?.message}
                    open={openSnackMessage}
                    onClose={() => openSnackBar(false)}
                    close={() => openSnackBar(false)}
                />
            </Grid> */}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <StyledMDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor={operationType == 'receita' ? "success" : "error"}
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="dark">
                                    {title}
                                </MDTypography>
                            </StyledMDBox>
                            <MDBox pt={4} pb={3} px={3}>
                                <MDBox component="form" role="form">
                                    <StyledInput mb={2} id="input-ativo" >
                                        <MDInput type="text" label="Nome" fullWidth classProp="formNewRevenueXExmpenseName"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)} />
                                    </StyledInput>
                                    <MDBox mb={2}>
                                        <MDInput type="number" label="Valor" fullWidth className="formNewRevenueXExmpenseValue"
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)} />
                                    </MDBox>

                                    <ThemeProvider theme={theme}>
                                        <LocalizationProvider
                                            adapterLocale="pt-br"
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <StyledDatePicker
                                                $isDarkTheme={darkMode}
                                                className="formNewRevenueXExmpenseDate"
                                                sx={{ display: 'flex', justifyContent: 'flex-end' }}
                                                // format="DD/MM/YYYY" 
                                                label="select date"
                                                onChange={handleDateChange}
                                                value={date ? dayjs(date) : null}
                                                PopperProps={{
                                                    modifiers: [
                                                        {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [0, 10], // Deslocamento para ajustar a posição
                                                            }
                                                        }
                                                    ]
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </ThemeProvider>

                                    <MDBox mb={2}>
                                        <RadioGroup
                                            className="formNewRevenueXExmpenseRadio"
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="receita"
                                            name="radio-buttons-group"
                                            row
                                            value={operationType}
                                            onChange={(e) => setOperationType(e.target.value)}
                                        >
                                            <FormControlLabel value="receita" control={<Radio />} label="Receita" />
                                            <FormControlLabel value="despesa" control={<Radio />} label="Despesa" />
                                        </RadioGroup>
                                    </MDBox>
                                    <MDBox display="flex" alignItems="center" ml={-1}>

                                    </MDBox>
                                    <MDBox mt={4} mb={1}>
                                        <MDButton disabled={isLoading} variant="gradient" color={"success"} fullWidth onClick={handleSave}>
                                            {isLoading
                                                ? <CircularProgress color="inherit" size={16} />
                                                :
                                                'Salvar'
                                            }

                                        </MDButton>
                                    </MDBox>
                                    {id && (<MDBox mt={2} mb={1}>
                                        <MDButton disabled={isLoading} variant="gradient" color={"error"} fullWidth onClick={handleDelete}>
                                            {isLoading
                                                ? <CircularProgress color="inherit" size={16} />
                                                :
                                                <Icon>delete</Icon>
                                            }

                                        </MDButton>
                                    </MDBox>)}
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </StyledContainer>
    );
}


const StyledMDBox = styled(MDBox)`
  background: radial-gradient(circle at 221% 201%, #f28371 0%, #f28371 65%, transparent 0%) ,#f0ba8d !important; 
`;


const StyledInput = styled(MDBox)`
[data-input] {
    & > div > div {
        // border: 1px solid red;
        height: 44px;
        
        & > input {
            padding: 0 5px !important;
        }
    }
}
`;

const StyledContainer = styled.div`
    width: 100%;

    [role="dialog"]{
        margin: 50px;
    }


    // @media screen and (max-width: 767px) {
    //     padding-top: 0px !important;
    //   }
`;

const StyledDatePicker = styled(DatePicker)`
width: 100%;

&>div{
    border-radius: 8px;
    height: 43px;
    padding: 0 3px;
}

input{
    padding: 9px 10px;
    font-size: 14px;
    color: rgb(73, 80, 87);
}

label{
    font-size: 14px;
    top: -4px;
}

button{
    right: 10px
}

    ${({ $isDarkTheme }) =>
        css` 
        label{
            color: ${$isDarkTheme ? '#FFFFFFCC' : '#7B809A'};
        }    

        div {
            height: 44.5px;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;

            &:hover fieldset {
                border-color: ${$isDarkTheme ? 'rgba(210, 214, 218, 0.6)' : '#d2d6da'} !important;
              }

              input {
                color: ${$isDarkTheme ? '#FFFFFFCC' : '#7B809A'};
              }
          
            fieldset{
                border-top-color: ${$isDarkTheme ? 'rgba(210, 214, 218, 0.6)' : '#d2d6da'};
                border-bottom-color: ${$isDarkTheme ? 'rgba(210, 214, 218, 0.6)' : '#d2d6da'};
                border-right-color: ${$isDarkTheme ? 'rgba(210, 214, 218, 0.6)' : '#d2d6da'};
                border-left-color: ${$isDarkTheme ? 'rgba(210, 214, 218, 0.6)' : '#d2d6da'};
            }

            path{
                color: ${$isDarkTheme ? '#FFFFFFCC' : '#7B809A'};
            }
        } 
    `}
`;

export default AddNewRevenueExpenseForm;