import { forwardRef, useEffect, useRef, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Api
import { Link, Navigate } from "react-router-dom";
import styled from "styled-components";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Autocomplete, FormControl, InputLabel, MenuItem, Pagination, Select, TextField } from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { useDebounce } from "use-lodash-debounce";
import getCacluatorData from "./calculadoraApi";
import { useMaterialUIController } from "context";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { EReInvestmentStrategy } from "./EStrategy";

const PRO_TIER = 3;

function Calculadora() {
    
    const [assetsToAport, setAssetsToAport] = useState([]);
    const [aportValue, setAportValue] = useState(null);
    const [controller] = useMaterialUIController();
    const [strategyType, setStrategyType] = useState(1);
    const { darkMode, userTier } = controller;

    const ITEMS_PER_PAGE = 200;
    const CRYPTO_ASSET_TYPE_ID = 9
    const aportValueDebounce = useDebounce(aportValue, 400);

    useEffect(() => {
        if (aportValueDebounce != null)
            calcute();
    }, [aportValueDebounce, strategyType]);

    const calcute = async () => {
        await getCacluatorData(aportValueDebounce, strategyType)
            .then(e => {
                setAssetsToAport(e);
            })
    }

    const inputRef = useRef(null);

    const strategyList = Object.entries(EReInvestmentStrategy)
        .filter(([key, value]) => !isNaN(Number(value))) // Filtra apenas as chaves numéricas
        .map(([key, value]) => ({
            id: Number(value), // O valor do enum
            label: key,        // O nome da chave
        }));

    const columnsx = [
        { Header: "Ativo", accessor: "name", width: "25%", align: "left" },
        { Header: "Quantidade", accessor: "quantity", align: "center" },
        { Header: "Valor", accessor: "value", align: "center" },
    ];

    const rowsx = assetsToAport.map(e => (
        {
            name: e.assetName,
            assetId: e.assetId,
            //   logo: <Company image={e.logo} name={e.ticker} />,
            quantity: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                    {e.assetType == CRYPTO_ASSET_TYPE_ID ? e.quantity : Math.floor(e.quantity)}
                </MDTypography>),
            value: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                    R$  {e.value?.toFixed(2)}
                </MDTypography>)
        }
    ));

    if (userTier != PRO_TIER){
        return <Navigate to="/pagamento" replace />
     }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Card className="tabelaAtivos">
                <StyledMDBox  p={3}>
                    <StyledMDBoxApportInput >
                        <ForwardedMDInput
                            ref={inputRef}
                            type="number"
                            label="Valor do aporte"
                            fullWidth
                            // value={score}
                            onChange={(e) => setAportValue(e.target.value)}
                            // error={score > 10}
                            errorMessage={'A nota máxima permitida é 10'}
                        />
                    </StyledMDBoxApportInput>

                    <StyledFormControl fullWidth >
                        <InputLabel id="demo-simple-select-label">Estratégia de aporte</InputLabel>
                        <StyledSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={strategyType}
                            label="Estratégia de aporte"
                            onChange={e => setStrategyType(e.target.value)}
                        >
                            {EReInvestmentStrategy?.map((e, key) => {
                                return (<MenuItem value={e.id}>{e.label}</MenuItem>)

                            })}
                        </StyledSelect>
                    </StyledFormControl>

                </StyledMDBox>

                <MDBox display="flex" justifyContent="space-between" alignItems="center" flexDirection='column' p={3}>
                    <DataTable
                        table={{ columns: columnsx, rows: rowsx }}
                        showTotalEntries={false}
                        isSorted={true}
                        noEndBorder
                        entriesPerPage={false}
                        itemsPerPage={ITEMS_PER_PAGE}
                        expandable
                    // clickableRow={(assetId, assetName) => openFormToGivePoints(assetId, assetName)}
                    // sortTable={sortTableDebounce}
                    // sortData={sortData}
                    />
                </MDBox>
            </Card>
        </DashboardLayout >
    );
}


const ForwardedMDInput = forwardRef((props, ref) => (
    <MDInput {...props} inputRef={ref} />
));

const StyledMDBox = styled(MDBox)`
    display: flex;
    align-items: center;

@media screen and (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

const StyledFormControl = styled(FormControl)`
    top: -1px;

    @media screen and (max-width: 900px) {
        top: 0;
      }
`;

const StyledSelect = styled(Select)`
    padding: 12px
`;

const StyledMDBoxApportInput = styled(MDBox)`
    //   margin-bottom: 2px;
      margin-Right: 20px;
      width: 20%;

      @media screen and (max-width: 900px) {
        width: 100%;
        margin-Right: 0;
        margin-bottom: 20px;
      }

`;


export default Calculadora;
